/* eslint-disable */



import React, { useState, useEffect, useContext } from 'react';
import FloormapContext from '../../layouts/Floormap/FloormapContext/FloormapContext';
import FloormapDistribucionContext from './FloormapDistribucionContext/FloormapDistribucionContext';
import isTruthy from '../../utils/isTruthy';
import { Switch } from 'antd';

import IconButton from '@material-ui/core/IconButton';

import Konva from 'konva';
import { Stage, Layer, Star, Text, Rect, Line, Image, Circle, Group, Transformer, RegularPolygon } from 'react-konva';
import useImage from 'use-image';
import moment from 'moment';


import './FloormapDistribucion.scss';

import * as FloormapApi from '../../api/restaurants/floormap';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
//Mesas
import mesa1 from '../../images/floormapElements/mesas/mesa1.png';
import mesa2 from '../../images/floormapElements/mesas/mesa2.png';
import mesa3 from '../../images/floormapElements/mesas/mesa3.png';
import mesa4 from '../../images/floormapElements/mesas/mesa4.png';
import mesa5 from '../../images/floormapElements/mesas/mesa5.png';
import mesa6 from '../../images/floormapElements/mesas/mesa6.png';
import mesa7 from '../../images/floormapElements/mesas/mesa7.png';
import mesa8 from '../../images/floormapElements/mesas/mesa8.png';
import mesa9 from '../../images/floormapElements/mesas/mesa9.png';
import mesa10 from '../../images/floormapElements/mesas/mesa10.png';
import mesa11 from '../../images/floormapElements/mesas/mesa11.png';
import mesa12 from '../../images/floormapElements/mesas/mesa12.png';
import mesa13 from '../../images/floormapElements/mesas/mesa13.png';
//Paredes
import pared1 from '../../images/floormapElements/paredes/pared1.png';
import pared2 from '../../images/floormapElements/paredes/pared2.png';
import pared3 from '../../images/floormapElements/paredes/pared3.png';
import pared4 from '../../images/floormapElements/paredes/pared4.png';
import pared5 from '../../images/floormapElements/paredes/pared5.png';
import pared6 from '../../images/floormapElements/paredes/pared6.png';
import pared7 from '../../images/floormapElements/paredes/pared7.png';
import pared8 from '../../images/floormapElements/paredes/pared8.png';
import pared9 from '../../images/floormapElements/paredes/pared9.png';
import pared10 from '../../images/floormapElements/paredes/pared10.png';
import pared11 from '../../images/floormapElements/paredes/pared11.png';
import pared12 from '../../images/floormapElements/paredes/pared12.png';
//Paredes casillas
import paredCasilla1 from '../../images/floormapElements/paredes/paredCasilla1.png';
import paredCasilla2 from '../../images/floormapElements/paredes/paredCasilla2.png';
import paredCasilla3 from '../../images/floormapElements/paredes/paredCasilla3.png';
import paredCasilla4 from '../../images/floormapElements/paredes/paredCasilla4.png';
import paredCasilla5 from '../../images/floormapElements/paredes/paredCasilla5.png';
import paredCasilla6 from '../../images/floormapElements/paredes/paredCasilla6.png';
import paredCasilla7 from '../../images/floormapElements/paredes/paredCasilla7.png';
import paredCasilla8 from '../../images/floormapElements/paredes/paredCasilla8.png';
import paredCasilla9 from '../../images/floormapElements/paredes/paredCasilla9.png';
import paredCasilla10 from '../../images/floormapElements/paredes/paredCasilla10.png';
import paredCasilla11 from '../../images/floormapElements/paredes/paredCasilla11.png';
import paredCasilla12 from '../../images/floormapElements/paredes/paredCasilla12.png';
//Decoraciones
import deco1 from '../../images/floormapElements/decoraciones/deco1.png';
import deco2 from '../../images/floormapElements/decoraciones/deco2.png';
import deco3 from '../../images/floormapElements/decoraciones/deco3.png';
import deco4 from '../../images/floormapElements/decoraciones/deco4.png';
import deco5 from '../../images/floormapElements/decoraciones/deco5.png';
import deco6 from '../../images/floormapElements/decoraciones/deco6.png';
//Zonas verdes
import verde1 from '../../images/floormapElements/zonasVerdes/plant1.png';
import verde2 from '../../images/floormapElements/zonasVerdes/plant2.png';
import verde3 from '../../images/floormapElements/zonasVerdes/plant3.png';
import verde4 from '../../images/floormapElements/zonasVerdes/plant4.png';
import verde5 from '../../images/floormapElements/zonasVerdes/plant5.png';
import verde6 from '../../images/floormapElements/zonasVerdes/plant6.png';
//Otros
import otro1 from '../../images/floormapElements/otros/otro1.png';
import otro2 from '../../images/floormapElements/otros/otro2.png';
import otro3 from '../../images/floormapElements/otros/otro3.png';
import otro4 from '../../images/floormapElements/otros/otro4.png';
import otro5 from '../../images/floormapElements/otros/otro5.png';
import otro6 from '../../images/floormapElements/otros/otro6.png';
import otro7 from '../../images/floormapElements/otros/otro7.png';
import otro8 from '../../images/floormapElements/otros/otro8.png';
import otro9 from '../../images/floormapElements/otros/otro9.png';
import otro10 from '../../images/floormapElements/otros/otro10.png';
import otro11 from '../../images/floormapElements/otros/otro11.png';
import otro12 from '../../images/floormapElements/otros/otro12.png';
import otro13 from '../../images/floormapElements/otros/otro13.png';
import otro14 from '../../images/floormapElements/otros/otro14.png';
//Formas
import forma1 from '../../images/floormapElements/formas/forma1.png';
import forma2 from '../../images/floormapElements/formas/forma2.png';
import forma3 from '../../images/floormapElements/formas/forma3.png';
import forma4 from '../../images/floormapElements/formas/forma4.png';

//Botones
import deshacer from '../../images/floormapElements/botonesAndOpciones/004-deshacer.png';
import rehacer from '../../images/floormapElements/botonesAndOpciones/005-rehacer.png';
import zoomMas from '../../images/floormapElements/botonesAndOpciones/002-zoom.png';
import zoomMenos from '../../images/floormapElements/botonesAndOpciones/003-zoom-1.png';
import reajustar from '../../images/floormapElements/botonesAndOpciones/001-pantalla-completa.png';
import texto from '../../images/floormapElements/botonesAndOpciones/texto.png';


import {
  Button,
  Box,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  Accordion,
  Container,
  Grid,
  TextField,
  CircularProgress,
  Hidden,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  NativeSelect,
  Checkbox,
  InputLabel,
  Typography,
  Modal
} from '@material-ui/core';
import FloormapItemMenu from '../FloormapItemMenu';
import FloormapShapeMenu from '../FloormapShapeMenu';
import FloormapTextMenu from '../FloormapTextMenu ';
import SpinnerLoader from '../SpinnerLoader';








const FloormapDistribucion = () => {

  let arrayEstados = [];

  //Estado de recarga

  const [reload, setReload] = useState(false);

  const [loading, setLoading] = useState(true);

  //Estado de recarga

  const [floors, setFloors] = useState([]);

  const [floorActual, setFloorActual] = useState(-1);

  const [floorVisibilidadActual, setFloorVisibilidadActual] = useState(1);

  const [accordionActual, setAccordionActual] = useState(0);

  const resizeScreen = () => {
    if(window.screen.width >= 1360){
      return 1;
    }else if(window.screen.width > 720){
      return 0.8;
    }else{
      return 0.4;
    }
  }

  const [sizeScreen, setSizeScreen] = useState(resizeScreen());

  let banderaResponsive = -1;



  matchMedia("(max-width: 720px)").addEventListener('change', (e) => {
    if (e.matches) {
      if(banderaResponsive != 1){
        banderaResponsive = 1;
        // The viewport is >= 1000px wide.
        let s = stateScaleActual / sizeScreen;
        setSizeScreen(resizeScreen());
        setStateScaleActual(s * resizeScreen());
        setState({
          stageScale: s * resizeScreen()
        });
      }
    }
  });

  matchMedia("(min-width: 720px) and (max-width: 1360px)").addEventListener('change', (e) => {
    if (e.matches) {
      if(banderaResponsive != 2){
        banderaResponsive = 2;
        // The viewport is >= 1000px wide.
        let s = stateScaleActual / sizeScreen;
        setSizeScreen(resizeScreen());
        setStateScaleActual(s * resizeScreen());
        setState({
          stageScale: s * resizeScreen()
        });
      }
    }
  });

  matchMedia("(min-width: 1360px)").addEventListener('change', (e) => {
    if (e.matches) {
      if(banderaResponsive != 3){
        banderaResponsive = 3;
        // The viewport is >= 1000px wide.
        let s = stateScaleActual / sizeScreen;
        setSizeScreen(resizeScreen());
        setStateScaleActual(s * resizeScreen());
        setState({
          stageScale: s * resizeScreen()
        });
      }
    }
  });

  // settear configuración de la API

  const currentCallConfig = () => {
    const retrieveReservationsConfig = localStorage.getItem('ReservationsConfig');
    const retrieveConfigDate = localStorage.getItem('ReservationsConfigDate');
    if (retrieveConfigDate === null) { localStorage.setItem('ReservationsConfigDate', moment().format('YYYY-MM-DD hh:mm a')); }
    const retrieveConfigZone = localStorage.getItem('ReservationsConfigZone');
    const retrieveConfigSortCategory = localStorage.getItem('ReservationsConfigSortCategory');
    const retrieveConfigSortDirection = localStorage.getItem('ReservationsConfigSortDirection');
    const config = {
      lastPage: retrieveReservationsConfig !== null
        ? JSON.parse(retrieveReservationsConfig).lastPage : 1,
      currentPage: retrieveReservationsConfig !== null
        ? JSON.parse(retrieveReservationsConfig).currentPage : 1,
      perPage: retrieveReservationsConfig !== null
        ? Number(JSON.parse(retrieveReservationsConfig).perPage)
        : 100,
      total: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).total : 0,
      from: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).from : 1,
      to: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).to : 5,
      options: [5, 25, 100],
      date: retrieveConfigDate !== null ? retrieveConfigDate.substring(0, 10) : moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      time: retrieveConfigDate !== null ? retrieveConfigDate.substring(11, retrieveConfigDate.length) : moment(new Date(), 'hh:mm a').format('hh:mm a'),
      zone: retrieveConfigZone !== null ? retrieveConfigZone : 'all',
      sortCategory: retrieveConfigSortCategory !== null ? retrieveConfigSortCategory : 'fecha',
      sortDirection: retrieveConfigSortDirection !== null ? retrieveConfigSortDirection : 'asc',
      search: '',
    };
    return config;
  };

  const [apiCallConfig, setApiCallConfig] = useState(currentCallConfig());

  // Llamadas a la API y useEffect

  useEffect(() => {
    if (reload) {
      setReload(false);
    }

    // Obtener el floormap

    const fetchFloormap = async () => {

      try{
        setLoading(true);
        let { success, data } = await FloormapApi
            .getFloormap();
        
        if (isTruthy(success)) {

          localStorage.setItem('FloormapId', data.id);
        }
        setLoading(false);        
      }catch (error) {
        setLoading(false);
      }

    }

    fetchFloormap();

    // Obtener pisos del floormap

    const fetchFloors = async () => {

      try{
        setLoading(true);

        const floormapId = localStorage.getItem('FloormapId');
        let { success, data } = await FloormapApi
            .getFloors(floormapId);
        

        if (isTruthy(success)) {

          let pisos = [];

          pisos = data.filter((item) => item.status == 1);

          setFloors(pisos);

          setFloorActual(pisos[0].id);

          setFloorVisibilidadActual(pisos[0].visibilidad);

          localStorage.setItem('FloorsConfig', JSON.stringify(pisos));



        }

        setLoading(false);
        
      }catch (error) {
        setLoading(false);
      }

    }

    fetchFloors();

    // Obtener items del floor

    const getFloorItems = async () => {

      try{
        setLoading(true);

        const floorOne = JSON.parse(localStorage.getItem('FloorsConfig'))[0];

        
        let { success, data } = await FloormapApi
            .getFloorItems(floorOne.id);
        
        setStateScaleActual(floorOne.escala * sizeScreen);
        

        setState({
          stageScale: floorOne.escala * sizeScreen
        });
        


        if (isTruthy(success)) {

          let idActual = 0;

          let numMesaActual = 0;
          

          data.forEach((item) => {
            if(item.idNum > idActual){
              idActual = item.idNum;
            }

            if(item.category == "mesa"){
              if(item.numMesa > numMesaActual){
                numMesaActual = item.numMesa;
              }
            }
          });

          idActual++;

          numMesaActual++;

          setId(idActual);

          setNumeroMesa(numMesaActual);

          setImages(data);

          setArrayDeshacer([]);
          setArrayRehacer([]);


        }

        setLoading(false);
        
      }catch (error) {
        setLoading(false);
      }

    }

    getFloorItems();

  }, [apiCallConfig, reload]);


  //Settear elementos de un floor

  const setFloorItemsFunc = async () => {

    const imagenes = images;

    let mesas = [];
    let paredes = [];
    let decoraciones = [];
    let verdes = [];
    let otros = [];
    let formas = [];
    let textos = [];

    imagenes.forEach((item) => {

      //Guardar mesas
      if(item.category == "mesa"){
        mesas.push(item);
      }

      //Guardar paredes
      if(item.category == "pared"){
        paredes.push(item);
      }

      //Guardar decoraciones
      if(item.category == "decoracion"){
        decoraciones.push(item);
      }

      //Guardar zonas verdes
      if(item.category == "verde"){
        verdes.push(item);
      }

      //Guardar otros
      if(item.category == "otro"){
        otros.push(item);
      }

      //Guardar formas
      if(item.category == "forma"){
        formas.push(item);
      }

      //Guardar textos
      if(item.category == "texto"){
        textos.push(item);
      }

    });

    const body = {
      "mesas": mesas,
      "paredes": paredes,
      "decoraciones": decoraciones,
      "verdes": verdes,
      "otros": otros,
      "formas": formas,
      "textos": textos
    }

    try{

      setLoading(true);
      
      let { success, data } = await FloormapApi
          .setFloorItems(floorActual, body);
      

      setLoading(false);

    }catch (error) {
      setLoading(false);

    }

  }

  // Cambio de piso

  const cambiarPiso = async (id) => {

    setLoading(true);

    // Cambiarpiso

    if(id != -1){
      setFloorActual(id);

      let { success, data } = await FloormapApi
            .getFloorItems(id);
        

        if (isTruthy(success)) {

          let idActual = 0;

          let numMesaActual = 0;
          

          data.forEach((item) => {
            if(item.idNum > idActual){
              idActual = item.idNum;
            }

            if(item.category == "mesa"){
              if(item.numMesa > numMesaActual){
                numMesaActual = item.numMesa;
              }
            }
          });

          idActual++;

          numMesaActual++;

          setId(idActual);

          setNumeroMesa(numMesaActual);

          setImages(data);

          setArrayDeshacer([]);
          setArrayRehacer([]);


        }
    }

    setLoading(false);

  }

  // Obtener escala

  const obtenerEscala = async (id) => {

    // obtenerEscala
    setLoading(true);

    if(id != -1){
      setFloorActual(id);

      let { success, data } = await FloormapApi
            .getScaleFloor(id);

      if (isTruthy(success)) {
        setStateScaleActual(data * sizeScreen);

        setState({
          stageScale: data * sizeScreen
        });
        
      }
    }

    setLoading(false);

  }

  // guardar escala

  const guardarEscala = async (value) => {

    setLoading(true);

    // guardarEscala
    const body = {
      "escala": value
    }

    let { success, data } = await FloormapApi
            .setScaleFloor(floorActual, body);

    if (isTruthy(success)) {
      setStateScaleActual(value * sizeScreen);

      setState({
        stageScale: value * sizeScreen
      });

      stageRef.current.setAbsolutePosition({x: 0, y: 0});
      
    }

    setLoading(false);

  }

  const esVisible = async (bandera) => {
        

    try{

      // guardarEscala
      const body = {
        "floor_id": floorActual,
        "visibilidad": bandera,
      }

      setLoading(true);
      
      let { success, data } = await FloormapApi
          .cambiarVisibilidad(body);
      

      if (isTruthy(success)) {
        setFloorVisibilidadActual(data.floor[0].visibilidad);
        setFloors(floors.map((floor) => {
  
          if(floor.id == floorActual){
            floor.visibilidad = data.floor[0].visibilidad;
          }

          return floor;
        }))
      }
      
      setLoading(false);

    }catch (error) {
      setLoading(false);

    }

  }

  const URLImage = ({ image, onBasicClick, onBasicTap, onBasicDblClick, limitarDrag, renovarCordenadas }) => {
  

    const drawHitFromCache = (img) => {
      //Usar solo lo que se renderize
      if(img){
        if(img.attrs.height != 0){
          img.cache();
          img.drawHitFromCache();
        }
      }
    };
  

    //Texto

    if(image.category == "texto"){

      return (
        <Text
          draggable
          fontSize={image.fontSize}
          ref={(node) => {
            if(selectedItem){
              if(imageNode.attrs.idNum == image.idNum){
                imageRef.current = node;
              }
            }
          }}
          onClick={onBasicClick}
          onTap={onBasicTap}
          onDblTap={onBasicDblClick}
          fill={image.fill}
          x={image.x}
          y={image.y}
          categoria={image.category}
          idNum={image.idNum}
          onDragMove={ (e) => {limitarDrag(e,2)}}
          onDragEnd = {(e) => {renovarCordenadas(e)}}
          text={image.text}
        />
      );
    }

    //Mesa

    if(image.category == "mesa"){

      const mapeo = Object.freeze({
        "1": mesa1,
        "2": mesa2,
        "3": mesa3,
        "4": mesa4,
        "5": mesa5,
        "6": mesa6,
        "7": mesa7,
        "8": mesa8,
        "9": mesa9,
        "10": mesa10,
        "11": mesa11,
        "12": mesa12,
        "13": mesa13,
      });

      const [img] = useImage(mapeo[image.type]);

      return (
        <>
          <Group
            draggable={image.draggable}
            ref={(node) => {
              if(selectedItem){
                if(imageNode.attrs.idNum == image.idNum){
                  imageRef.current = node;
                }
              }
            }}
            visibilidad={image.visibilidad}
            tipoSilla={image.tipoSilla}
            blocked={image.blocked}
            personaMinimas={image.personaMinimas}
            personaMaximas={image.personaMaximas}
            numMesa={image.numMesa}
            x={image.x}
            y={image.y}
            width={image.ancho}
            height={image.largo}
            categoria={image.category}
            type={image.type}
            idNum={image.idNum}
            onDragMove={ (e) => {limitarDrag(e,2)}}
            onDragEnd = {(e) => {renovarCordenadas(e)}}
            onClick={onBasicClick}
            onTap={onBasicTap}
            onDblTap={onBasicDblClick}
            image={img}
            onTransformEnd={(e) => {
              // transformer is changing scale of the node
              // and NOT its width or height
              // but in the store we have only width and height
              // to match the data better we will reset scale on transform end
              const node = imageRef.current;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();
              
              //Guardar estado
              setArrayDeshacer(arrayDeshacer.concat([
                JSON.parse(JSON.stringify(images))
              ]));
              setArrayRehacer([]);
              // we will reset it back
              node.scaleX(1);
              node.scaleY(1);
              setImages(images.map((im) => {

                if(imageNode.attrs.idNum == im.idNum){
                  im.ancho = node.width() * scaleX;
                  im.largo = node.height() * scaleY;
                }

                return im;
              }))
            }}
          >

            <Image
              ref={(node) => {drawHitFromCache(node)}}
              image={img}
              width={image.ancho}
              height={image.largo}
            />
            <Text 
              ref={(node) => {
                
                drawHitFromCache(node)
              }}
              text={image.numMesa} 
              fill="#000000" 
              wrap="char" 
              align="center"
              verticalAlign="middle"
              fontSize={20}
              width={image.ancho}
              height={image.largo}
            />
          </Group>
          {selectedItem && imageNode.attrs.idNum == image.idNum && (
            <Transformer
              ref={trRef}
              flipEnabled={false}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (Math.abs(newBox.width) < (darDimensiones(image.category, image.type).ancho / 1.5) || Math.abs(newBox.height) < (darDimensiones(image.category, image.type).alto / 1.5) || Math.abs(newBox.width) > (darDimensiones(image.category, image.type).ancho * 1.5) || Math.abs(newBox.height) > (darDimensiones(image.category, image.type).alto * 1.5)) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
        </>
      );
    };
    
    //Pared

    if(image.category == "pared"){

      const mapeo = Object.freeze({
        "1": pared1,
        "2": pared2,
        "3": pared3,
        "4": pared4,
        "5": pared5,
        "6": pared6,
        "7": pared7,
        "8": pared8,
        "9": pared9,
        "10": pared10,
        "11": pared11,
        "12": pared12
      });

      

      if(image.type == 3){

        const [img] = useImage(mapeo["1"]);

        return (
          <>
            <Image
              draggable
              rotation={-45}
              ref={(node) => {
                drawHitFromCache(node)
                if(selectedItem){
                  if(imageNode.attrs.idNum == image.idNum){
                    imageRef.current = node;
                  }
                }
              }}
              onTransformEnd={(e) => {
                // transformer is changing scale of the node
                // and NOT its width or height
                // but in the store we have only width and height
                // to match the data better we will reset scale on transform end
                const node = imageRef.current;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();
      
                //Guardar estado
                setArrayDeshacer(arrayDeshacer.concat([
                  JSON.parse(JSON.stringify(images))
                ]));
                setArrayRehacer([]);
                // we will reset it back
                node.scaleX(1);
                node.scaleY(1);
                setImages(images.map((im) => {
  
                  if(imageNode.attrs.idNum == im.idNum){
                    im.ancho = node.width() * scaleX;
                    im.largo = node.height() * scaleY;
                  }
  
                  return im;
                }))
              }}
              onClick={onBasicClick}
              onTap={onBasicTap}
              onDblTap={onBasicDblClick}
              image={img}
              width={image.ancho}
              height={image.largo}
              x={image.x}
              y={image.y}
              categoria={image.category}
              type={image.type}
              idNum={image.idNum}
              onDragMove={ (e) => {limitarDrag(e,2)}}
              onDragEnd = {(e) => {renovarCordenadas(e)}}
            />
            {selectedItem && imageNode.attrs.idNum == image.idNum && (
              <Transformer
                ref={trRef}
                flipEnabled={false}
                rotateEnabled={false}
                boundBoxFunc={(oldBox, newBox) => {
                  // limit resize
                  if (Math.abs(newBox.width) < (darDimensiones(image.category, image.type).ancho / 1.5) || Math.abs(newBox.height) < (darDimensiones(image.category, image.type).alto / 1.5) ) {
                    return oldBox;
                  }
                  return newBox;
                }}
              />
            )}
          </>
        );

      }else if(image.type == 4){

        const [img] = useImage(mapeo["1"]);

        return (
          <>
            <Image
              draggable
              rotation={45}
              ref={(node) => {
                drawHitFromCache(node)
                if(selectedItem){
                  if(imageNode.attrs.idNum == image.idNum){
                    imageRef.current = node;
                  }
                }
              }}
              onTransformEnd={(e) => {
                // transformer is changing scale of the node
                // and NOT its width or height
                // but in the store we have only width and height
                // to match the data better we will reset scale on transform end
                const node = imageRef.current;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();
      
                //Guardar estado
                setArrayDeshacer(arrayDeshacer.concat([
                  JSON.parse(JSON.stringify(images))
                ]));
                setArrayRehacer([]);
                // we will reset it back
                node.scaleX(1);
                node.scaleY(1);
                setImages(images.map((im) => {
  
                  if(imageNode.attrs.idNum == im.idNum){
                    im.ancho = node.width() * scaleX;
                    im.largo = node.height() * scaleY;
                  }
  
                  return im;
                }))
              }}
              onClick={onBasicClick}
              onTap={onBasicTap}
              onDblTap={onBasicDblClick}
              image={img}
              width={image.ancho}
              height={image.largo}
              x={image.x}
              y={image.y}
              categoria={image.category}
              type={image.type}
              idNum={image.idNum}
              onDragMove={ (e) => {limitarDrag(e,2)}}
              onDragEnd = {(e) => {renovarCordenadas(e)}}
            />
            {selectedItem && imageNode.attrs.idNum == image.idNum && (
              <Transformer
                ref={trRef}
                flipEnabled={false}
                rotateEnabled={false}
                boundBoxFunc={(oldBox, newBox) => {
                  // limit resize
                  if (Math.abs(newBox.width) < (darDimensiones(image.category, image.type).ancho / 1.5) || Math.abs(newBox.height) < (darDimensiones(image.category, image.type).alto / 1.5) ) {
                    return oldBox;
                  }
                  return newBox;
                }}
              />
            )}
          </>
        );

      }else{

        const [img] = useImage(mapeo[image.type]);


        return (
          <>
            <Image
              draggable
              ref={(node) => {
                drawHitFromCache(node)
                if(selectedItem){
                  if(imageNode.attrs.idNum == image.idNum){
                    imageRef.current = node;
                  }
                }
              }}
              onTransformEnd={(e) => {
                // transformer is changing scale of the node
                // and NOT its width or height
                // but in the store we have only width and height
                // to match the data better we will reset scale on transform end
                const node = imageRef.current;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();
      
                //Guardar estado
                setArrayDeshacer(arrayDeshacer.concat([
                  JSON.parse(JSON.stringify(images))
                ]));
                setArrayRehacer([]);
                // we will reset it back
                node.scaleX(1);
                node.scaleY(1);
                setImages(images.map((im) => {
  
                  if(imageNode.attrs.idNum == im.idNum){
                    im.ancho = node.width() * scaleX;
                    im.largo = node.height() * scaleY;
                  }
  
                  return im;
                }))
              }}
              onClick={onBasicClick}
              onTap={onBasicTap}
              onDblTap={onBasicDblClick}
              image={img}
              width={image.ancho}
              height={image.largo}
              x={image.x}
              y={image.y}
              categoria={image.category}
              type={image.type}
              idNum={image.idNum}
              onDragMove={ (e) => {limitarDrag(e,2)}}
              onDragEnd = {(e) => {renovarCordenadas(e)}}
            />
            {selectedItem && imageNode.attrs.idNum == image.idNum && (
              <Transformer
                ref={trRef}
                flipEnabled={false}
                rotateEnabled={false}
                boundBoxFunc={(oldBox, newBox) => {
                  // limit resize
                  if (Math.abs(newBox.width) < (darDimensiones(image.category, image.type).ancho / 1.5) || Math.abs(newBox.height) < (darDimensiones(image.category, image.type).alto / 1.5) ) {
                    return oldBox;
                  }
                  return newBox;
                }}
              />
            )}
          </>
        );
      }
  
      
    
      
    };


    //Decoración

    if(image.category == "decoracion"){
      
      const mapeo = Object.freeze({
        "1": deco1,
        "2": deco2,
        "3": deco3,
        "4": deco4,
        "5": deco5,
        "6": deco6
      });

      const [img] = useImage(mapeo[image.type]);

      
    
      return (
        <>
          <Image
            draggable
            ref={(node) => {
              drawHitFromCache(node)
              if(selectedItem){
                if(imageNode.attrs.idNum == image.idNum){
                  imageRef.current = node;
                }
              }
            }}
            onTransformEnd={(e) => {
              // transformer is changing scale of the node
              // and NOT its width or height
              // but in the store we have only width and height
              // to match the data better we will reset scale on transform end
              const node = imageRef.current;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();
    
              //Guardar estado
              setArrayDeshacer(arrayDeshacer.concat([
                JSON.parse(JSON.stringify(images))
              ]));
              setArrayRehacer([]);
              // we will reset it back
              node.scaleX(1);
              node.scaleY(1);
              setImages(images.map((im) => {

                if(imageNode.attrs.idNum == im.idNum){
                  im.ancho = node.width() * scaleX;
                  im.largo = node.height() * scaleY;
                }

                return im;
              }))
            }}
            onClick={onBasicClick}
            onTap={onBasicTap}
            onDblTap={onBasicDblClick}
            image={img}
            width={image.ancho}
            height={image.largo}
            x={image.x}
            y={image.y}
            categoria={image.category}
            type={image.type}
            idNum={image.idNum}
            onDragMove={ (e) => {limitarDrag(e,2)}}
            onDragEnd = {(e) => {renovarCordenadas(e)}}
          />
          {selectedItem && imageNode.attrs.idNum == image.idNum && (
            <Transformer
              ref={trRef}
              flipEnabled={false}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (Math.abs(newBox.width) < (darDimensiones(image.category, image.type).ancho / 1.5) || Math.abs(newBox.height) < (darDimensiones(image.category, image.type).alto / 1.5) || Math.abs(newBox.width) > (darDimensiones(image.category, image.type).ancho * 1.5) || Math.abs(newBox.height) > (darDimensiones(image.category, image.type).alto * 1.5)) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
        </>
      );
    };

    //Zonas Verdes
    if(image.category == "verde"){

      const mapeo = Object.freeze({
        "1": verde1,
        "2": verde2,
        "3": verde3,
        "4": verde4,
        "5": verde5,
        "6": verde6
      });

      const [img] = useImage(mapeo[image.type]);
      
      
    
      return (
        <>
          <Image
            draggable
            ref={(node) => {
              drawHitFromCache(node)
              if(selectedItem){
                if(imageNode.attrs.idNum == image.idNum){
                  imageRef.current = node;
                }
              }
            }}
            onTransformEnd={(e) => {
              // transformer is changing scale of the node
              // and NOT its width or height
              // but in the store we have only width and height
              // to match the data better we will reset scale on transform end
              const node = imageRef.current;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();
    
              //Guardar estado
              setArrayDeshacer(arrayDeshacer.concat([
                JSON.parse(JSON.stringify(images))
              ]));
              setArrayRehacer([]);
              // we will reset it back
              node.scaleX(1);
              node.scaleY(1);
              setImages(images.map((im) => {

                if(imageNode.attrs.idNum == im.idNum){
                  im.ancho = node.width() * scaleX;
                  im.largo = node.height() * scaleY;
                }

                return im;
              }))
            }}
            onClick={onBasicClick}
            onTap={onBasicTap}
            onDblTap={onBasicDblClick}
            image={img}
            width={image.ancho}
            height={image.largo}
            x={image.x}
            y={image.y}
            categoria={image.category}
            type={image.type}
            idNum={image.idNum}
            onDragMove={ (e) => {limitarDrag(e,2)}}
            onDragEnd = {(e) => {renovarCordenadas(e)}}
          />
          {selectedItem && imageNode.attrs.idNum == image.idNum && (
            <Transformer
              ref={trRef}
              flipEnabled={false}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (Math.abs(newBox.width) < (darDimensiones(image.category, image.type).ancho / 1.5) || Math.abs(newBox.height) < (darDimensiones(image.category, image.type).alto / 1.5) || Math.abs(newBox.width) > (darDimensiones(image.category, image.type).ancho * 1.5) || Math.abs(newBox.height) > (darDimensiones(image.category, image.type).alto * 1.5)) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
        </>
      );
    };

    //Otros objetos
    if(image.category == "otro"){

      const mapeo = Object.freeze({
        "1": otro1,
        "2": otro2,
        "3": otro3,
        "4": otro4,
        "5": otro5,
        "6": otro6,
        "7": otro7,
        "8": otro8,
        "9": otro9,
        "10": otro10,
        "11": otro11,
        "12": otro12,
        "13": otro13,
        "14": otro14,
      });

      const [img] = useImage(mapeo[image.type]);
  
      
    
      return (
        <>
          <Image
            draggable
            ref={(node) => {
              drawHitFromCache(node)
              if(selectedItem){
                if(imageNode.attrs.idNum == image.idNum){
                  imageRef.current = node;
                }
              }
            }}
            onTransformEnd={(e) => {
              // transformer is changing scale of the node
              // and NOT its width or height
              // but in the store we have only width and height
              // to match the data better we will reset scale on transform end
              const node = imageRef.current;
              const scaleX = node.scaleX();
              const scaleY = node.scaleY();
    
              //Guardar estado
              setArrayDeshacer(arrayDeshacer.concat([
                JSON.parse(JSON.stringify(images))
              ]));
              setArrayRehacer([]);
              // we will reset it back
              node.scaleX(1);
              node.scaleY(1);
              setImages(images.map((im) => {

                if(imageNode.attrs.idNum == im.idNum){
                  im.ancho = node.width() * scaleX;
                  im.largo = node.height() * scaleY;
                }

                return im;
              }))
            }}
            onClick={onBasicClick}
            onTap={onBasicTap}
            onDblTap={onBasicDblClick}
            image={img}
            width={image.ancho}
            height={image.largo}
            x={image.x}
            y={image.y}
            categoria={image.category}
            type={image.type}
            idNum={image.idNum}
            onDragMove={ (e) => {limitarDrag(e,2)}}
            onDragEnd = {(e) => {renovarCordenadas(e)}}
          />
          {selectedItem && imageNode.attrs.idNum == image.idNum && (
            <Transformer
              ref={trRef}
              flipEnabled={false}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (Math.abs(newBox.width) < (darDimensiones(image.category, image.type).ancho / 1.5) || Math.abs(newBox.height) < (darDimensiones(image.category, image.type).alto / 1.5) || Math.abs(newBox.width) > (darDimensiones(image.category, image.type).ancho * 1.5) || Math.abs(newBox.height) > (darDimensiones(image.category, image.type).alto * 1.5)) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
        </>
      );
    };

    if(image.category == "forma"){

      

      //Selecionar forma
      if(image.type == "1"){
        //Cuadrado
        return(
          <>
            <Rect
              draggable
              onClick={onBasicClick}
              onTap={onBasicTap}
              onDblTap={onBasicDblClick}
              ref={(node) => {
                if(selectedItem){
                  if(imageNode.attrs.idNum == image.idNum){
                    imageRef.current = node;
                  }
                }
              }}
              onTransformEnd={(e) => {
                // transformer is changing scale of the node
                // and NOT its width or height
                // but in the store we have only width and height
                // to match the data better we will reset scale on transform end
                const node = imageRef.current;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();
      
                //Guardar estado
                setArrayDeshacer(arrayDeshacer.concat([
                  JSON.parse(JSON.stringify(images))
                ]));
                setArrayRehacer([]);
                // we will reset it back
                node.scaleX(1);
                node.scaleY(1);
                setImages(images.map((im) => {

                  if(imageNode.attrs.idNum == im.idNum){
                    im.ancho = node.width() * scaleX;
                    im.largo = node.height() * scaleY;
                  }

                  return im;
                }))
              }}
              x={image.x}
              y={image.y}
              width={image.ancho}
              height={image.largo}
              stroke={image.stroke}
              fill={image.fill}
              strokeWidth={image.strokeWidth}
              categoria={image.category}
              type={image.type}
              idNum={image.idNum}
              onDragMove={ (e) => {limitarDrag(e,2)}}
              onDragEnd = {(e) => {renovarCordenadas(e)}}
            />
            {selectedItem && imageNode.attrs.idNum == image.idNum && (
            <Transformer
              ref={trRef}
              flipEnabled={false}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (Math.abs(newBox.width) < 15 || Math.abs(newBox.height) < 15) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
          </>
        )
      }else if(image.type == "2"){
        //Triangulo
        return(
          <>
            <RegularPolygon
              draggable
              onClick={onBasicClick}
              onTap={onBasicTap}
              onDblTap={onBasicDblClick}
              x={image.x}
              y={image.y}
              sides={3}
              radius={image.ancho}
              ref={(node) => {
                if(selectedItem){
                  if(imageNode.attrs.idNum == image.idNum){
                    imageRef.current = node;
                  }
                }
              }}
              onTransformEnd={(e) => {
                // transformer is changing scale of the node
                // and NOT its width or height
                // but in the store we have only width and height
                // to match the data better we will reset scale on transform end
                const node = imageRef.current;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();
      
                //Guardar estado
                setArrayDeshacer(arrayDeshacer.concat([
                  JSON.parse(JSON.stringify(images))
                ]));
                setArrayRehacer([]);
                // we will reset it back
                node.scaleX(1);
                node.scaleY(1);
                setImages(images.map((im) => {

                  if(imageNode.attrs.idNum == im.idNum){
                    im.ancho = node.radius() * scaleX;
                    im.largo = node.radius() * scaleY;
                  }

                  return im;
                }))
              }}
              closed
              stroke={image.stroke}
              fill={image.fill}
              strokeWidth={image.strokeWidth}
              categoria={image.category}
              type={image.type}
              idNum={image.idNum}
              onDragMove={ (e) => {limitarDrag(e,2)}}
              onDragEnd = {(e) => {renovarCordenadas(e)}}
            />
            {selectedItem && imageNode.attrs.idNum == image.idNum && (
            <Transformer
              ref={trRef}
              flipEnabled={false}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (Math.abs(newBox.width) < 15 || Math.abs(newBox.height) < 15) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
          </>
        )
      }else if(image.type == "3"){
        //Circulo
        return(
          <>
            <Circle 
              draggable
              onClick={onBasicClick}
              onTap={onBasicTap}
              onDblTap={onBasicDblClick}
              ref={(node) => {
                if(selectedItem){
                  if(imageNode.attrs.idNum == image.idNum){
                    imageRef.current = node;
                  }
                }
              }}
              onTransformEnd={(e) => {
                // transformer is changing scale of the node
                // and NOT its width or height
                // but in the store we have only width and height
                // to match the data better we will reset scale on transform end
                const node = imageRef.current;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();
      
                //Guardar estado
                setArrayDeshacer(arrayDeshacer.concat([
                  JSON.parse(JSON.stringify(images))
                ]));
                setArrayRehacer([]);
                // we will reset it back
                node.scaleX(1);
                node.scaleY(1);
                setImages(images.map((im) => {

                  if(imageNode.attrs.idNum == im.idNum){
                    im.ancho = node.radius() * scaleX;
                    im.largo = node.radius() * scaleY;
                  }

                  return im;
                }))
              }}
              x={image.x}
              y={image.y}
              radius={image.ancho}
              stroke={image.stroke}
              fill={image.fill}
              strokeWidth={image.strokeWidth}
              // fill="rgba(255,255,0,1)"
              categoria={image.category}
              type={image.type}
              idNum={image.idNum}
              onDragMove={ (e) => {limitarDrag(e,2)}}
              onDragEnd = {(e) => {renovarCordenadas(e)}}
            />
            {selectedItem && imageNode.attrs.idNum == image.idNum && (
            <Transformer
              ref={trRef}
              flipEnabled={false}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (Math.abs(newBox.width) < 15 || Math.abs(newBox.height) < 15) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
          </>
        )
      }else if(image.type == "4"){
        //Rectangulo
        return(
          <>
            <Rect
              draggable
              onClick={onBasicClick}
              onTap={onBasicTap}
              onDblTap={onBasicDblClick}
              ref={(node) => {
                if(selectedItem){
                  if(imageNode.attrs.idNum == image.idNum){
                    imageRef.current = node;
                  }
                }
              }}
              onTransformEnd={(e) => {
                // transformer is changing scale of the node
                // and NOT its width or height
                // but in the store we have only width and height
                // to match the data better we will reset scale on transform end
                const node = imageRef.current;
                const scaleX = node.scaleX();
                const scaleY = node.scaleY();
      
                //Guardar estado
                setArrayDeshacer(arrayDeshacer.concat([
                  JSON.parse(JSON.stringify(images))
                ]));
                setArrayRehacer([]);
                // we will reset it back
                node.scaleX(1);
                node.scaleY(1);
                setImages(images.map((im) => {

                  if(imageNode.attrs.idNum == im.idNum){
                    im.ancho = node.width() * scaleX;
                    im.largo = node.height() * scaleY;
                  }

                  return im;
                }))
              }}
              x={image.x}
              y={image.y}
              width={image.ancho}
              height={image.largo}
              stroke={image.stroke}
              fill={image.fill}
              strokeWidth={image.strokeWidth}
              categoria={image.category}
              type={image.type}
              idNum={image.idNum}
              onDragMove={ (e) => {limitarDrag(e,2)}}
              onDragEnd = {(e) => {renovarCordenadas(e)}}
            />
            {selectedItem && imageNode.attrs.idNum == image.idNum && (
            <Transformer
              ref={trRef}
              flipEnabled={false}
              rotateEnabled={false}
              boundBoxFunc={(oldBox, newBox) => {
                // limit resize
                if (Math.abs(newBox.width) < 15 || Math.abs(newBox.height) < 15) {
                  return oldBox;
                }
                return newBox;
              }}
            />
          )}
          </>
        )
      }
  
    };


  }

    
  
  //Variables para monitoriar botones de zoom
  const [estadoBotonMasZoom, setEstadoBotonMasZoom] = useState(true);
  const [estadoBotonMenosZoom, setEstadoBotonMenosZoom] = useState(false);
  
  //Transparencia
  const [transparenciaActual, setTransparenciaActual] = useState(1)

  //las escalas seran 1, 0.8 y 0.5
  const [stateScaleActual, setStateScaleActual] = useState(1 * sizeScreen)

  //Zona escala del stage
  const [state, setState] = useState({
    stageScale: 1 * sizeScreen
  });

  const zoomFunc = (e, opc) => {
    e.preventDefault();

    const scaleBy = 1.10;
    const stage = stageRef.current;
    const oldScale = state.stageScale;
    const mousePointTo = {
      x: 400 / oldScale - stage.x() / oldScale,
      y: 200 / oldScale - stage.y() / oldScale
    };

    let newScale;

    switch(opc){
      case 1:
        newScale = oldScale * scaleBy
        break;
      case 2:
        newScale = oldScale / scaleBy
        break;
      case 0:
        newScale = stateScaleActual
        break;
    }

    //Seguro para evitar escalar mal
    if(newScale > stateScaleActual * scaleBy * scaleBy * scaleBy){
      return;
    }

    if(newScale < stateScaleActual){
      newScale = stateScaleActual
    }

    if(state.stageScale >= stateScaleActual && newScale > stateScaleActual){
      stage.draggable(true);
      setState({
        stageScale: newScale
      });
      stageRef.current.setAbsolutePosition({
        x: -(mousePointTo.x - 400 / newScale) * newScale, 
        y: -(mousePointTo.y - 200 / newScale) * newScale
      });
    }else{
      stage.draggable(false);
      setState({
        stageScale: newScale
      });
      stageRef.current.setAbsolutePosition({x: 0, y: 0})
    }

    if(newScale == stateScaleActual * scaleBy * scaleBy * scaleBy){
      setEstadoBotonMasZoom(false);
    }else{
      setEstadoBotonMasZoom(true);
    }

    if(newScale == stateScaleActual){
      setEstadoBotonMenosZoom(false);
    }else{
      setEstadoBotonMenosZoom(true);
    }
  };

  //imagenes drag

  const dragUrl = React.useRef();
  const stageRef = React.useRef();
  const [coordTouch, setCoordTouch] = useState();
  const [esTouch, setEsTouch] = useState(false);
  const categoryOfElement = React.useRef();
  const typeOfElement = React.useRef();
  const trRef = React.useRef();
  const imageRef = React.useRef();

  //Ocultar menu con touches

  const [ocultarMenu, setOcultarMenu] = useState(false);

  //Modal de elementos en movil open={modalTextoAbierto}
  const [modalElementsMenu, setModalElementsMenu] = useState(false);
  const abrirModalElementsMenu = () => setModalElementsMenu(true);
  const cerrarModalElementsMenu = () => {
    setModalElementsMenu(false);
    esDobleClick.current = false;
  }  

  //Texto y modal
  const [modalTextoAbierto, setModalTextoAbierto] = useState(false);
  const abrirModalTexto = () => setModalTextoAbierto(true);
  const cerrarModalTexto = () => {
    const textoVar =  document.querySelector("#campoTexto");
    textoVar.value = "";
    setModalTextoAbierto(false);
  }

  const crearTexto = () =>{
    const textoVar =  document.querySelector("#campoTexto");

    if(textoVar.value == ""){
      cerrarModalTexto();
    }else{
      if(stageRef.current.attrs.x){
        if(esTouch){
          setImages(
            images.concat([
              {
                text: textoVar.value,
                x: (coordTouch.x - stageRef.current.attrs.x) / state.stageScale,
                y: (coordTouch.y - stageRef.current.attrs.y)  / state.stageScale,
                category: categoryOfElement.current,
                fill: "black",
                fontSize: 20,
                idNum: asignarId()
              },
            ])
          );
        }else{
          setImages(
            images.concat([
              {
                text: textoVar.value,
                x: (stageRef.current.getPointerPosition().x - stageRef.current.attrs.x) / state.stageScale,
                y: (stageRef.current.getPointerPosition().y - stageRef.current.attrs.y)  / state.stageScale,
                category: categoryOfElement.current,
                fill: "black",
                fontSize: 20,
                idNum: asignarId()
              },
            ])
          );
        }
      }else{
        if(esTouch){
          setImages(
            images.concat([
              {
                text: textoVar.value,
                x: (coordTouch.x)  / state.stageScale,
                y: (coordTouch.y)  / state.stageScale,
                src: dragUrl.current,
                category: categoryOfElement.current,
                fill: "black",
                fontSize: 20,
                idNum: asignarId()
              },
            ])
          );
        }else{
          setImages(
            images.concat([
              {
                text: textoVar.value,
                x: (stageRef.current.getPointerPosition().x)  / state.stageScale,
                y: (stageRef.current.getPointerPosition().y)  / state.stageScale,
                src: dragUrl.current,
                category: categoryOfElement.current,
                fill: "black",
                fontSize: 20,
                idNum: asignarId()
              },
            ])
          );
        }
      }
      cerrarModalTexto();
    }

  }

  //Arreglo de elementos
  const [images, setImages] = useState([]);

  //Ids
  const [id, setId] = useState(1);

  //Numero de la mesa
  const [numeroMesa, setNumeroMesa] = useState(1);

  //Estado de selección para el menu
  const [selectedItem, setSelectedItem] = useState(false);
  const [selectedItemDbl, setSelectedItemDbl] = useState(false);
  const cerrarSelectedItem = () => {
    setSelectedItem(false);
    setSelectedItemDbl(false);
    esDobleClick.current = false;
  }  

  //Estado de selección para el menu del stage
  const [menuStageEnable, setMenuStageEnable] = useState(false);
  const cerrarMenuStageEnable = () => {
    setMenuStageEnable(false);
  }  

  //Nodo de la imagen
  const [imageNode, setImageNode] = useState();

  //Zona de transformación

  useEffect(() =>{
    if(selectedItem){
      if(imageRef.current.attrs.categoria != "texto"){
        if((imageRef.current.attrs.categoria == "forma" && (imageRef.current.attrs.type == 2 || imageRef.current.attrs.type == 3)) || imageRef.current.attrs.categoria == "mesa"){
          trRef.current.enabledAnchors([
            "top-right",
            "top-left",
            "bottom-right",
            "bottom-left"])
        }
        if(imageRef.current.attrs.categoria == "pared"){
          if(imageRef.current.attrs.type == 1){
            trRef.current.enabledAnchors([
              "top-center",
              "bottom-center",
            ])
          }else if(imageRef.current.attrs.type == 2){
            trRef.current.enabledAnchors([
              "middle-right",
              "middle-left",
            ])
          }else if(imageRef.current.attrs.type == 3){
            trRef.current.enabledAnchors([
              "top-center",
              "bottom-center",
            ])
          }else if(imageRef.current.attrs.type == 4){
            trRef.current.enabledAnchors([
              "top-center",
              "bottom-center",
            ])
          }else{
            trRef.current.enabledAnchors([])
          }
        }        
        trRef.current.nodes([imageRef.current]);
        trRef.current.getLayer().batchDraw();
      }  
    }

    if(selectedItem == false){
      esDobleClick.current = false;
      setSelectedItemDbl(false);
    }
  },[selectedItem])

  //Zona canva
  const grid = 20
  const gridWidth = 801 / stateScaleActual

  const linesA = [] // Rayas verticales
  const linesB = [] // Rayas horizontales

  if(transparenciaActual == 0){

    const auxA = [];
    const auxB = [];

    for (let i = 0; i < gridWidth / grid; i++) {
      auxA.push(
        <Line
          strokeWidth={2}
          stroke={'black'}
          points={[i * grid, 0, i * grid, gridWidth/2]}
          opacity={0.5}
        />
      )

      if(i < (gridWidth / grid)/2){
        auxB.push(
          <Line
            strokeWidth={2}
            stroke={'black'}
            points={[0, i * grid, gridWidth, i * grid]}
            opacity={0.5}
          />
        )
      }

    }
    linesA.push(auxA[0]);
    linesA.push(auxA[auxA.length - 1]);

    linesB.push(auxB[0]);
    linesB.push(auxB[auxB.length - 1]);
  }else{
    
    for (let i = 0; i < gridWidth / grid; i++) {
      linesA.push(
        <Line
          strokeWidth={2}
          stroke={'grey'}
          points={[i * grid, 0, i * grid, gridWidth/2]}
          opacity={transparenciaActual}
        />
      )

      if(i < (gridWidth / grid)/2){
        linesB.push(
          <Line
            strokeWidth={2}
            stroke={'grey'}
            points={[0, i * grid, gridWidth, i * grid]}
            opacity={transparenciaActual}
          />
        )
      }

    }
  }

  //Fin zona canva


  const asignarId = () => {
    let idAsignada = id;
    setId(id + 1);
    return idAsignada;
  }

  const asignarNumeroMesa = () => {
    let numeroMesaAsignado = numeroMesa;
    setNumeroMesa(numeroMesa + 1);
    return numeroMesaAsignado;
  }

  const darDimensiones = (categoria, tipo) => {

    const dimensiones = {
      ancho: 0,
      alto: 0,
    }

    if(categoria == "mesa"){
      if(tipo == 1){
        dimensiones.alto = 79.5;
        dimensiones.ancho = 39;
      }

      if(tipo == 2){
        dimensiones.alto = 76.5;
        dimensiones.ancho = 36
      }

      if(tipo == 3){
        dimensiones.alto = 79.5;
        dimensiones.ancho = 54;
      }

      if(tipo == 4){
        dimensiones.alto = 79.5;
        dimensiones.ancho = 78;
      }

      if(tipo == 5){
        dimensiones.ancho = 78;
        dimensiones.alto = 79.5;
      }

      if(tipo == 6){
        dimensiones.ancho = 72;
        dimensiones.alto = 79.5;
      }

      if(tipo == 7){
        dimensiones.ancho = 87;
        dimensiones.alto = 82.5;
      }

      if(tipo == 8){
        dimensiones.ancho = 114;
        dimensiones.alto = 79.5;
      }

      if(tipo == 9){
        dimensiones.ancho = 112.5;
        dimensiones.alto = 114;
      }

      if(tipo == 10){
        dimensiones.ancho = 106.5;
        dimensiones.alto = 79.5;
      }

      if(tipo == 11){
        dimensiones.ancho = 147;
        dimensiones.alto = 79.5;
      }

      if(tipo == 12){
        dimensiones.ancho = 73.5;
        dimensiones.alto = 79.5;
      }

      if(tipo == 13){
        dimensiones.ancho = 94.5;
        dimensiones.alto = 79.5;
      }
    }

    if(categoria == "pared"){
      if(tipo == 1){
        dimensiones.ancho = 10;
        dimensiones.alto = 65;
      }else if(tipo == 2){
        dimensiones.ancho = 65;
        dimensiones.alto = 10;
      }else if(tipo == 3){
        dimensiones.ancho = 10;
        dimensiones.alto = 65;
      }else if(tipo == 4){
        dimensiones.ancho = 10;
        dimensiones.alto = 65;
      }else{
        dimensiones.ancho = 65;
        dimensiones.alto = 65;
      }
    }

    if(categoria == "decoracion"){
      dimensiones.ancho = 120;
      dimensiones.alto = 120;
    }

    if(categoria == "verde"){
      dimensiones.ancho = 120;
      dimensiones.alto = 120;
    }

    if(categoria == "otro"){
      if(tipo == 1){
        dimensiones.alto = 78;
        dimensiones.ancho = 153;
      }

      if(tipo == 9){
        dimensiones.ancho = 78;
        dimensiones.alto = 153;
      }

      if(tipo == 10){
        dimensiones.alto = 78;
        dimensiones.ancho = 153;
      }

      if(tipo == 11){
        dimensiones.ancho = 78;
        dimensiones.alto = 153;
      }

      if(tipo == 2){
        dimensiones.alto = 90;
        dimensiones.ancho = 108;
      }

      if(tipo == 3){
        dimensiones.alto = 81;
        dimensiones.ancho = 90;
      }

      if(tipo == 4){
        dimensiones.alto = 39;
        dimensiones.ancho = 132;
      }

      if(tipo == 5){
        dimensiones.alto = 105;
        dimensiones.ancho = 172;
      }

      if(tipo == 12){
        dimensiones.ancho = 105;
        dimensiones.alto = 172;
      }

      if(tipo == 13){
        dimensiones.alto = 105;
        dimensiones.ancho = 172;
      }

      if(tipo == 14){
        dimensiones.ancho = 105;
        dimensiones.alto = 172;
      }

      if(tipo == 6){
        dimensiones.alto = 75;
        dimensiones.ancho = 90;
      }


      if(tipo == 7){
        dimensiones.alto = 75;
        dimensiones.ancho = 90;
      }


      if(tipo == 8){
        dimensiones.alto = 75;
        dimensiones.ancho = 90;
      }
    }

    if(categoria == "forma"){
      if(tipo == 1){
        dimensiones.alto = 80;
        dimensiones.ancho = 80;
      }

      if(tipo == 2){
        dimensiones.alto = 40; //Punto final triangulo
        dimensiones.ancho = 40; //Punto inicial triangulo
      }

      if(tipo == 3){
        dimensiones.alto = 40;
        dimensiones.ancho = 40;
      }

      if(tipo == 4){
        dimensiones.alto = 60;
        dimensiones.ancho = 120;
      }
    }

    return dimensiones;
  }

  const esDobleClick = React.useRef(false);

  const onBasicClick = (e) => {
    setMenuStageEnable(false)
    setSelectedItem(false);
    setImageNode(e.currentTarget);
    setSelectedItem(true);
  };

  const onBasicTap = (e) => {
    const evento = e.currentTarget;

    setTimeout(() => {
      if(!esDobleClick.current){
        setMenuStageEnable(false)
        setSelectedItem(false);
        setImageNode(evento);
        setSelectedItem(true);
      }
    }, 500);    
  };

  const onBasicDblClick = (e) => {
    e.cancelBubble = true; //prevent event bubbling
    esDobleClick.current = true;
    setMenuStageEnable(false)
    setSelectedItem(false);
    setImageNode(e.currentTarget);
    setSelectedItem(true);
    setSelectedItemDbl(true);
  };

  const limitarDrag = (e, opc) => {

    e.cancelBubble = true; //prevent event bubbling 

    console.log(e);
    console.log(stageRef.current.width());

    //const absPos = stageRef.current.getAbsolutePosition();

    const absPos = e.currentTarget.getAbsolutePosition();


    const newAbsPos = {...absPos};


    if(opc == 1){
      // Limitar Eje X
      if(absPos.x < (stageRef.current.width() * stateScaleActual - stageRef.current.width() * state.stageScale) / stateScaleActual){
        newAbsPos.x =   (stageRef.current.width() * stateScaleActual - stageRef.current.width() * state.stageScale) / stateScaleActual;
      }

      if(absPos.x > 0){
        newAbsPos.x =  0;
      }

      //Limitar Eje Y
      if(absPos.y < (stageRef.current.height() * stateScaleActual - stageRef.current.height() * state.stageScale) / stateScaleActual){
        newAbsPos.y = (stageRef.current.height() * stateScaleActual - stageRef.current.height() * state.stageScale) / stateScaleActual;
      }

      if(absPos.y > 0){
        newAbsPos.y =  0;
      }
    }else if(opc == 2){
      //Limitar Eje X
      if(absPos.x > stageRef.current.width() - (e.currentTarget.attrs.width * stateScaleActual)){
        newAbsPos.x =   stageRef.current.width() - (e.currentTarget.attrs.width * stateScaleActual);
      }

      if(absPos.x < 0){
        newAbsPos.x =  0;
      }

      //Limiar Eje Y
      if(absPos.y > stageRef.current.height()  - (e.currentTarget.attrs.height * stateScaleActual)){
        newAbsPos.y = stageRef.current.height()  - (e.currentTarget.attrs.height * stateScaleActual);
      }

      if(absPos.y < 0){
        newAbsPos.y =  0;
      }
    }

    if(opc == 1){
      stageRef.current.setAbsolutePosition({x: newAbsPos.x, y: newAbsPos.y});
    }else if(opc == 2){
      e.currentTarget.setAbsolutePosition({x: newAbsPos.x, y: newAbsPos.y});
    }
  }

  //Renovar cordenadas de un objeto
  const renovarCordenadas = (e) =>{

    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = e.target.attrs.idNum;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.x = e.target.attrs.x;
        image.y = e.target.attrs.y;
        if(selectedItem){
          imageNode.attrs.x = e.target.attrs.x;
          imageNode.attrs.y = e.target.attrs.y;
        }
      }
      return image;
    }));
    
  }

  //Rehacer y deshacer
  const [arrayRehacer, setArrayRehacer] = useState([]);  
  const [arrayDeshacer, setArrayDeshacer] = useState([]);    

  const deshacerFunc = () => {
    
    //Guardar el estado en rehacer
    setArrayRehacer(arrayRehacer.concat([
      JSON.parse(JSON.stringify(images))
    ]))
    //Copiar array de deshacer
    const arrayEstados = JSON.parse(JSON.stringify(arrayDeshacer));
    //Obtener un estado
    const estado = arrayEstados.pop();
    //Cargar el estado
    setImages(estado);
    //Actualizar el array de deshacer
    setArrayDeshacer(arrayEstados);

    setSelectedItem(false);
  }

  const rehacerFunc = () => {
    //Guardar el estado en Deshacer
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]))
    //Copiar array de deshacer
    const arrayEstados = JSON.parse(JSON.stringify(arrayRehacer));
    //Obtener un estado
    const estado = arrayEstados.pop();
    //Cargar el estado
    setImages(estado);
    //Actualizar el array de deshacer
    setArrayRehacer(arrayEstados);

    setSelectedItem(false);
  }

  const DropdownIndicator = props => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
         <ExpandMoreIcon />
        </components.DropdownIndicator>
      )
    );
  };

  //Función para las mesas
  const mesaFunc = (e) => {
    typeOfElement.current = e.target.dataset.tipo;
    dragUrl.current = e.target.src;
    categoryOfElement.current = "mesa";
  }

  const paredFunc = (e) => {
    typeOfElement.current = e.target.dataset.tipo;
    dragUrl.current = e.target.src;
    categoryOfElement.current = "pared";
  }

  const decoFunc = (e) => {
    typeOfElement.current = e.target.dataset.tipo;
    dragUrl.current = e.target.src;
    categoryOfElement.current = "decoracion";
  }

  const verdeFunc = (e) => {
    typeOfElement.current = e.target.dataset.tipo;
    dragUrl.current = e.target.src;
    categoryOfElement.current = "verde";
  }

  const otroFunc = (e) => {
    typeOfElement.current = e.target.dataset.tipo;
    dragUrl.current = e.target.src;
    categoryOfElement.current = "otro";
  }

  const formaFunc = (e) => {
    typeOfElement.current = e.target.dataset.tipo;
    dragUrl.current = e.target.src;
    categoryOfElement.current = "forma";
  }

  const textFunc = (e) => {
    typeOfElement.current = null;
    dragUrl.current = null;
    categoryOfElement.current = "texto";
  }

  const insertarElemento = (e) => {
    e.preventDefault();
    // register event position
    stageRef.current.setPointersPositions(e);

    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    //Verificar si se hizo drag
    if(stageRef.current.attrs.x){
      
      //Verificar si es texto

      if(categoryOfElement.current == "texto"){
        setEsTouch(false);
        abrirModalTexto();
      }else if(categoryOfElement.current == "mesa"){
        let personaMax = 0;
        let personaMin = 0;

        // Asignar personas maximas y minimas
        if(typeOfElement.current == 1 || typeOfElement.current == 2){
          personaMax = 2;
          personaMin = 1;
        }else if(typeOfElement.current == 3 || typeOfElement.current == 4 || typeOfElement.current == 5 || typeOfElement.current == 6){
          personaMax = 4;
          personaMin = 1;
        }else if(typeOfElement.current == 7){
          personaMax = 5;
          personaMin = 1;
        }else if(typeOfElement.current == 8 || typeOfElement.current == 10 || typeOfElement.current == 12){
          personaMax = 6;
          personaMin = 1;
        }else if(typeOfElement.current == 9 || typeOfElement.current == 11 || typeOfElement.current == 13){
          personaMax = 8;
          personaMin = 1;
        }
        // Verificar si es una mesa
        setImages(
          images.concat([
            {
              x: (stageRef.current.getPointerPosition().x - stageRef.current.attrs.x) / state.stageScale,
              y: (stageRef.current.getPointerPosition().y - stageRef.current.attrs.y)  / state.stageScale,
              src: dragUrl.current,
              category: categoryOfElement.current,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 2,
              type: typeOfElement.current,
              idNum: asignarId(),
              tipoSilla: "Sillas altas de bar",
              visibilidad: true,
              blocked: false,
              draggable: true,
              personaMinimas: personaMin,
              personaMaximas: personaMax,
              numMesa: asignarNumeroMesa(),
              largo: darDimensiones(categoryOfElement.current, typeOfElement.current).alto,
              ancho: darDimensiones(categoryOfElement.current, typeOfElement.current).ancho,
            },
          ])
        );
      }else{
        setImages(
          images.concat([
            {
              x: (stageRef.current.getPointerPosition().x - stageRef.current.attrs.x) / state.stageScale,
              y: (stageRef.current.getPointerPosition().y - stageRef.current.attrs.y)  / state.stageScale,
              src: dragUrl.current,
              category: categoryOfElement.current,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 2,
              type: typeOfElement.current,
              idNum: asignarId(),
              largo: darDimensiones(categoryOfElement.current, typeOfElement.current).alto,
              ancho: darDimensiones(categoryOfElement.current, typeOfElement.current).ancho,
            },
          ])
        );
      }

    }else{

      //Verificar si es texto

      if(categoryOfElement.current == "texto"){
        setEsTouch(false);
        abrirModalTexto();
      }else if(categoryOfElement.current == "mesa"){
        // Verificar si es una mesa

        let personaMax = 0;
        let personaMin = 0;

        // Asignar personas maximas y minimas
        if(typeOfElement.current == 1 || typeOfElement.current == 2){
          personaMax = 2;
          personaMin = 1;
        }else if(typeOfElement.current == 3 || typeOfElement.current == 4 || typeOfElement.current == 5 || typeOfElement.current == 6){
          personaMax = 4;
          personaMin = 1;
        }else if(typeOfElement.current == 7){
          personaMax = 5;
          personaMin = 1;
        }else if(typeOfElement.current == 8 || typeOfElement.current == 10 || typeOfElement.current == 12){
          personaMax = 6;
          personaMin = 1;
        }else if(typeOfElement.current == 9 || typeOfElement.current == 11 || typeOfElement.current == 13){
          personaMax = 8;
          personaMin = 1;
        }

        setImages(
          images.concat([
            {
              x: (stageRef.current.getPointerPosition().x)  / state.stageScale,
              y: (stageRef.current.getPointerPosition().y)  / state.stageScale,
              src: dragUrl.current,
              category: categoryOfElement.current,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 2,
              type: typeOfElement.current,
              idNum: asignarId(),
              tipoSilla: "Sillas altas de bar",
              visibilidad: true,
              blocked: false,
              draggable: true,
              personaMinimas: personaMin,
              personaMaximas: personaMax,
              numMesa: asignarNumeroMesa(),
              largo: darDimensiones(categoryOfElement.current, typeOfElement.current).alto,
              ancho: darDimensiones(categoryOfElement.current, typeOfElement.current).ancho,
            },
          ])
        );
      }else{
        // add image
        setImages(
          images.concat([
            {
              x: (stageRef.current.getPointerPosition().x)  / state.stageScale,
              y: (stageRef.current.getPointerPosition().y)  / state.stageScale,
              src: dragUrl.current,
              category: categoryOfElement.current,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 2,
              type: typeOfElement.current,
              idNum: asignarId(),
              largo: darDimensiones(categoryOfElement.current, typeOfElement.current).alto,
              ancho: darDimensiones(categoryOfElement.current, typeOfElement.current).ancho,
            },
          ])
        );
      }
    }
  }

  const insertarElementoTouch = (coord) => {

    //coord es donde esta el mouse cuando se suelta la figura
    //cx posicion de la grilla

    let cx = stageRef.current.attrs.container.getBoundingClientRect().left + window.scrollX;
    let cy = stageRef.current.attrs.container.getBoundingClientRect().top + window.scrollY;

    if((coord.x < cx) || (coord.x > (cx + stageRef.current.attrs.container.getBoundingClientRect().width)) || (coord.y < cy) || (coord.y > (cy + stageRef.current.attrs.container.getBoundingClientRect().height)))
    {
      return;
    }

    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    //Verificar si se hizo drag
    if(stageRef.current.attrs.x){
      
      //Verificar si es texto

      if(categoryOfElement.current == "texto"){
        setCoordTouch({
          x: coord.x -cx,
          y: coord.y -cy
        })
        setEsTouch(true);
        abrirModalTexto();
      }else if(categoryOfElement.current == "mesa"){
        let personaMax = 0;
        let personaMin = 0;

        // Asignar personas maximas y minimas
        if(typeOfElement.current == 1 || typeOfElement.current == 2){
          personaMax = 2;
          personaMin = 1;
        }else if(typeOfElement.current == 3 || typeOfElement.current == 4 || typeOfElement.current == 5 || typeOfElement.current == 6){
          personaMax = 4;
          personaMin = 1;
        }else if(typeOfElement.current == 7){
          personaMax = 5;
          personaMin = 1;
        }else if(typeOfElement.current == 8 || typeOfElement.current == 10 || typeOfElement.current == 12){
          personaMax = 6;
          personaMin = 1;
        }else if(typeOfElement.current == 9 || typeOfElement.current == 11 || typeOfElement.current == 13){
          personaMax = 8;
          personaMin = 1;
        }
        // Verificar si es una mesa
        setImages(
          images.concat([
            {
              x: (coord.x -cx - stageRef.current.attrs.x) / state.stageScale,
              y: (coord.y -cy - stageRef.current.attrs.y)  / state.stageScale,
              src: dragUrl.current,
              category: categoryOfElement.current,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 2,
              type: typeOfElement.current,
              idNum: asignarId(),
              tipoSilla: "Sillas altas de bar",
              visibilidad: true,
              blocked: false,
              draggable: true,
              personaMinimas: personaMin,
              personaMaximas: personaMax,
              numMesa: asignarNumeroMesa(),
              largo: darDimensiones(categoryOfElement.current, typeOfElement.current).alto,
              ancho: darDimensiones(categoryOfElement.current, typeOfElement.current).ancho,
            },
          ])
        );
      }else{
        setImages(
          images.concat([
            {
              x: (coord.x -cx - stageRef.current.attrs.x) / state.stageScale,
              y: (coord.y -cy - stageRef.current.attrs.y)  / state.stageScale,
              src: dragUrl.current,
              category: categoryOfElement.current,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 2,
              type: typeOfElement.current,
              idNum: asignarId(),
              largo: darDimensiones(categoryOfElement.current, typeOfElement.current).alto,
              ancho: darDimensiones(categoryOfElement.current, typeOfElement.current).ancho,
            },
          ])
        );
      }

    }else{

      //Verificar si es texto

      if(categoryOfElement.current == "texto"){
        setCoordTouch({
          x: coord.x -cx,
          y: coord.y -cy
        })
        setEsTouch(true);
        abrirModalTexto();
      }else if(categoryOfElement.current == "mesa"){
        // Verificar si es una mesa

        let personaMax = 0;
        let personaMin = 0;

        // Asignar personas maximas y minimas
        if(typeOfElement.current == 1 || typeOfElement.current == 2){
          personaMax = 2;
          personaMin = 1;
        }else if(typeOfElement.current == 3 || typeOfElement.current == 4 || typeOfElement.current == 5 || typeOfElement.current == 6){
          personaMax = 4;
          personaMin = 1;
        }else if(typeOfElement.current == 7){
          personaMax = 5;
          personaMin = 1;
        }else if(typeOfElement.current == 8 || typeOfElement.current == 10 || typeOfElement.current == 12){
          personaMax = 6;
          personaMin = 1;
        }else if(typeOfElement.current == 9 || typeOfElement.current == 11 || typeOfElement.current == 13){
          personaMax = 8;
          personaMin = 1;
        }

        setImages(
          images.concat([
            {
              x: (coord.x - cx)  / state.stageScale,
              y: (coord.y - cy)  / state.stageScale,
              src: dragUrl.current,
              category: categoryOfElement.current,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 2,
              type: typeOfElement.current,
              idNum: asignarId(),
              tipoSilla: "Sillas altas de bar",
              visibilidad: true,
              blocked: false,
              draggable: true,
              personaMinimas: personaMin,
              personaMaximas: personaMax,
              numMesa: asignarNumeroMesa(),
              largo: darDimensiones(categoryOfElement.current, typeOfElement.current).alto,
              ancho: darDimensiones(categoryOfElement.current, typeOfElement.current).ancho,
            },
          ])
        );
      }else{
        // add image
        setImages(
          images.concat([
            {
              x: (coord.x - cx)  / state.stageScale,
              y: (coord.y - cy)  / state.stageScale,
              src: dragUrl.current,
              category: categoryOfElement.current,
              fill: "transparent",
              stroke: "black",
              strokeWidth: 2,
              type: typeOfElement.current,
              idNum: asignarId(),
              largo: darDimensiones(categoryOfElement.current, typeOfElement.current).alto,
              ancho: darDimensiones(categoryOfElement.current, typeOfElement.current).ancho,
            },
          ])
        );
      }
    }
  }

  const renderItemMenu = () => (
    <>
      <FloormapDistribucionContext.Provider value={{ arrayRehacer, setArrayRehacer, arrayDeshacer, setArrayDeshacer, id, setId, images, setImages, selectedItem, setSelectedItem, imageNode, setImageNode, numeroMesa, setNumeroMesa, sizeScreen}}>
        <FloormapItemMenu />
      </FloormapDistribucionContext.Provider>
    </>
  );

  const renderShapeMenu = () => (
    <>
      <FloormapDistribucionContext.Provider value={{ arrayRehacer, setArrayRehacer, arrayDeshacer, setArrayDeshacer, id, setId, images, setImages, selectedItem, setSelectedItem, imageNode, setImageNode }}>
        <FloormapShapeMenu />
      </FloormapDistribucionContext.Provider>
    </>
  );

  const renderTextMenu = () => (
    <>
      <FloormapDistribucionContext.Provider value={{ arrayRehacer, setArrayRehacer, arrayDeshacer, setArrayDeshacer, id, setId, images, setImages, selectedItem, setSelectedItem, imageNode, setImageNode }}>
        <FloormapTextMenu />
      </FloormapDistribucionContext.Provider>
    </>
  );

  const renderStageMenu = () => {

    return(
      <>
        <div className={sizeScreen == 0.4 ? 'mb-2' : 'border-menu-accordion mb-2'}>
            <div className='border-bottom pt-1 pb-1'>
              <div className='ml-1'>
                <Typography className='bold-font'>Configuración de zona</Typography>
              </div>
            </div>
            <div className={sizeScreen == 0.4 ? 'pt-1 pb-1 w100 dFlex flex-column align-center justify-center' : 'border-bottom pt-1 pb-1 w100 dFlex flex-column align-center justify-center'}>
              

              <div className='mt-05 ml-1 dFlex flex-column  justify-between mr-1'>
                <Typography variant='body2' className='bold-font'>Tamaño de zona</Typography>
                <div className='dFlex flex-column align-center justify-between mt-05'>
                  <TextField
                    select
                    defaultValue={(stateScaleActual / sizeScreen).toFixed(1) == 1 ? 1 : (stateScaleActual / sizeScreen).toFixed(1) == 0.5 ? 0.5 : 0.8 }
                    onChange={(e) => {
                
                      guardarEscala(e.target.value);
                      
                    }}
                    SelectProps={{
                      IconComponent:ExpandMoreIcon,
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center"
                        },
                        getContentAnchorEl: null
                      }
                    }}
                    
                    className='border-select w110'
                  >
                    <MenuItem key={1} value={1}>
                      <Typography className='tiny-font pading-item-select' variant='body2'>Pequeña</Typography>
                    </MenuItem>
                    <MenuItem key={0.8} value={0.8}>
                      <Typography className='tiny-font pading-item-select' variant='body2'>Mediana</Typography>
                    </MenuItem>
                    <MenuItem key={0.5} value={0.5}>
                      <Typography className='tiny-font pading-item-select' variant='body2'>Grande</Typography>
                    </MenuItem>
                  </TextField>
                </div>
              </div>

              <div className='mt-05 ml-1 dFlex flex-column  justify-between mr-1'>
                <Typography variant='body2' className='bold-font'>Ajustes de cuadricula</Typography>
                <div className='dFlex flex-column align-center justify-between mt-05'>
                  <TextField
                    select
                    onChange={(e) => {
                
                      setTransparenciaActual(e.target.value);
                      
                    }}
                    defaultValue={transparenciaActual}
                    SelectProps={{
                      IconComponent:ExpandMoreIcon,
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "center"
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "center"
                        },
                        getContentAnchorEl: null
                      }
                    }}
                    
                    className='border-select w110'
                  >
                    <MenuItem key={1} value={1}>
                      <Typography className='tiny-font pading-item-select' variant='body2'>Sin transparencia</Typography>
                    </MenuItem>
                    <MenuItem key={0.2} value={0.2}>
                      <Typography className='tiny-font pading-item-select' variant='body2'>Transparencia Media</Typography>
                    </MenuItem>
                    <MenuItem key={0} value={0}>
                      <Typography className='tiny-font pading-item-select' variant='body2'>Transparencia total</Typography>
                    </MenuItem>
                  </TextField>
                </div>
              </div>
              <div className='mt-05 ml-1 dFlex flex-column align-center justify-between mr-1'>
                <Typography variant='body2' className='bold-font'>Visibilidad</Typography>
                <div className='dFlex flex-column align-center justify-between mt-05'>
                <div className='ml-1 mt-05 dFlex flex-row align-center justify-between mr-1'>
                  <Typography variant='body2' className='bold-font'>¿Visible al público?</Typography>
                  <FormControlLabel labelPlacement="start" control={<Switch className="switcher" color="primary" onChange={(value) => {esVisible(value)}} checked={Boolean(floorVisibilidadActual) } />} />
                </div>
                </div>
              </div>
            </div>
          </div>
      </>
    );
  };

  const renderElementsMenu = () => {

    return(
      <>
        {/*Primer menu*/}

        {accordionActual != 6 ? 
          <div className='border-menu-accordion mb-2'>
            <div className='border-bottom pt-1 pb-1'>
              <div className='ml-1'>
                <Typography className='bold-font'>Elementos</Typography>
              </div>
            </div>
            {/*Mesas*/}
            {accordionActual == 1 || accordionActual == 0 ?
              <div className='border-bottom'>
                <Accordion >
                  <AccordionSummary
                    className='pr-2e'
                    expandIcon={<ExpandMoreIcon htmlColor={"black"} />}
                    onClick={() => {
                      if(accordionActual == 1){
                        setAccordionActual(0);
                      }else{
                        setAccordionActual(1);
                      }
                    }}
                  >
                    <Typography className='pl-1'>Mesas</Typography>
                  </AccordionSummary>
                  <AccordionDetails className='border-top'>
                    <Grid container className='align-center justify-center' >
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img  src={mesa1} alt="Mesa 1" data-tipo="1"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={mesa2} alt="Mesa 2" data-tipo="2"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={mesa3} alt="Mesa 3" data-tipo="3"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={mesa4} alt="Mesa 4" data-tipo="4"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={mesa5} alt="Mesa 5" data-tipo="5"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={mesa6} alt="Mesa 6" data-tipo="6"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={mesa7} alt="Mesa 7" data-tipo="7"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={mesa8} alt="Mesa 8" data-tipo="8"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc();
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={mesa9} alt="Mesa 9" data-tipo="9"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={mesa10} alt="Mesa 10" data-tipo="10"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={mesa11} alt="Mesa 11" data-tipo="11"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={mesa12} alt="Mesa 12" data-tipo="12"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={mesa13} alt="Mesa 13" data-tipo="13"
                          onDragStart={mesaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            mesaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
              : null
            }
            {/*Paredes*/}
            {accordionActual == 2 || accordionActual == 0 ?
              <div className='border-bottom'>
                <Accordion>
                  <AccordionSummary className='pr-2e'
                    expandIcon={<ExpandMoreIcon htmlColor={"black"} />}
                    onClick={() => {
                      if(accordionActual == 2){
                        setAccordionActual(0);
                      }else{
                        setAccordionActual(2);
                      }
                    }}
                  >
                    <Typography className='pl-1'>Paredes</Typography>
                  </AccordionSummary>
                  <AccordionDetails className='border-top'>
                    <Grid container className='align-center justify-center' >
                      <Grid item md={3} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={paredCasilla1} alt="Pared 1" data-tipo="1"
                          onDragStart={paredFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            paredFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={3} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={paredCasilla2} alt="Pared 2" data-tipo="2"
                          onDragStart={paredFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            paredFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid><Grid item md={3} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={paredCasilla3} alt="Pared 3" data-tipo="3"
                          onDragStart={paredFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            paredFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid><Grid item md={3} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={paredCasilla4} alt="Pared 4" data-tipo="4"
                          onDragStart={paredFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            paredFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid><Grid item md={3} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={paredCasilla5} alt="Pared 5" data-tipo="5"
                          onDragStart={paredFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            paredFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid><Grid item md={3} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={paredCasilla6} alt="Pared 6" data-tipo="6"
                          onDragStart={paredFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            paredFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid><Grid item md={3} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={paredCasilla7} alt="Pared 7" data-tipo="7"
                          onDragStart={paredFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            paredFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid><Grid item md={3} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={paredCasilla8} alt="Pared 8" data-tipo="8"
                          onDragStart={paredFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            paredFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid><Grid item md={3} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={paredCasilla9} alt="Pared 9" data-tipo="9"
                          onDragStart={paredFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            paredFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid><Grid item md={3} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={paredCasilla10} alt="Pared 10" data-tipo="10"
                          onDragStart={paredFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            paredFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid><Grid item md={3} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={paredCasilla11} alt="Pared 11" data-tipo="11"
                          onDragStart={paredFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            paredFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid><Grid item md={3} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={paredCasilla12} alt="Pared 12" data-tipo="12"
                          onDragStart={paredFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            paredFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
              : null
            }
            {/*Decoraciones*/}
            {accordionActual == 3 || accordionActual == 0 ?
              <div className='border-bottom'>
                <Accordion >
                  <AccordionSummary
                    className='pr-2e'
                    expandIcon={<ExpandMoreIcon htmlColor={"black"} />}
                    onClick={() => {
                      if(accordionActual == 3){
                        setAccordionActual(0);
                      }else{
                        setAccordionActual(3);
                      }
                    }}
                  >
                    <Typography className='pl-1'>Decoraciones</Typography>
                  </AccordionSummary>
                  <AccordionDetails className='border-top'>
                    <Grid container className='align-center justify-center' >
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img  src={otro1} alt="Otro 1" data-tipo="1"
                          onDragStart={otroFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            otroFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={otro2} alt="Otro 2" data-tipo="2"
                          onDragStart={otroFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            otroFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={otro3} alt="Otro 3" data-tipo="3"
                          onDragStart={otroFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            otroFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={otro4} alt="Otro 4" data-tipo="4"
                          onDragStart={otroFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            otroFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={12} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={otro5} alt="Otro 5" data-tipo="5"
                          onDragStart={otroFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            otroFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={otro6} alt="Otro 6" data-tipo="6" width="80px" height="66.6px"
                          onDragStart={otroFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            otroFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={otro7} alt="Otro 7" data-tipo="7" width="80px" height="66.6px"
                          onDragStart={otroFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            otroFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={otro8} alt="Otro 8" data-tipo="8" width="80px" height="66.6px"
                          onDragStart={otroFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            otroFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
              : null
            }
            {/*Zonas Verdes*/}
            {accordionActual == 4 || accordionActual == 0 ?
              <div className='border-bottom'>
                <Accordion >
                  <AccordionSummary
                    className='pr-2e'
                    expandIcon={<ExpandMoreIcon htmlColor={"black"} />}
                    onClick={() => {
                      if(accordionActual == 4){
                        setAccordionActual(0);
                      }else{
                        setAccordionActual(4);
                      }
                    }}
                  >
                    <Typography className='pl-1'>Plantas</Typography>
                  </AccordionSummary>
                  <AccordionDetails className='border-top'>
                    <Grid container className='align-center justify-center' >
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img  src={verde1} alt="zona verde 1" data-tipo="1"
                          onDragStart={verdeFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            verdeFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={verde2} alt="zona verde 2" data-tipo="2"
                          onDragStart={verdeFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            verdeFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={verde3} alt="zona verde 3" data-tipo="3"
                          onDragStart={verdeFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            verdeFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={verde4} alt="zona verde 4" data-tipo="4"
                          onDragStart={verdeFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            verdeFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={verde5} alt="zona verde 5" data-tipo="5"
                          onDragStart={verdeFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            verdeFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={verde6} alt="zona verde 6" data-tipo="6"
                          onDragStart={verdeFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            verdeFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img  src={deco1} alt="Decoracion 1" data-tipo="1"
                          onDragStart={decoFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            decoFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={deco2} alt="Decoracion 2" data-tipo="2"
                          onDragStart={decoFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            decoFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={deco3} alt="Decoracion 3" data-tipo="3"
                          onDragStart={decoFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            decoFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={deco4} alt="Decoracion 4" data-tipo="4"
                          onDragStart={decoFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            decoFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={deco5} alt="Decoracion 5" data-tipo="5"
                          onDragStart={decoFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            decoFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={4} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={deco6} alt="Decoracion 6" data-tipo="6"
                          onDragStart={decoFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            decoFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
              : null
            }
          </div>
        : null
        }

        {/*Segundo menu*/}

        { accordionActual == 0 || accordionActual == 6 ?
            <div className={sizeScreen == 0.4 ? 'border-menu-accordion mb-2 w100' : 'border-menu-accordion mb-2'}>
              <div className='border-bottom pt-1 pb-1'>
                <div className='ml-1'>
                  <Typography className='bold-font'>Formas y Textos</Typography>
                </div>
              </div>
    
              {/*Formas*/}
              <div className='border-bottom'>
                <Accordion >
                  <AccordionSummary
                    className='pr-2e'
                    expandIcon={<ExpandMoreIcon htmlColor={"black"} />}
                    onClick={() => {
                      if(accordionActual == 6){
                        setAccordionActual(0);
                      }else{
                        setAccordionActual(6);
                      }
                    }}
                  >
                    <Typography className='pl-1'>Formas</Typography>
                  </AccordionSummary>
                  <AccordionDetails className='border-top'>
                    <Grid container className='align-center justify-center' >
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img  src={forma1} alt="forma 1" data-tipo="1"
                          onDragStart={formaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            formaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={forma2} alt="forma 2" data-tipo="2"
                          onDragStart={formaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            formaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={forma3} alt="forma 3" data-tipo="3"
                          onDragStart={formaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            formaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                      <Grid item md={6} className='align-center justify-center dFlex flex-column border-menu-accordion-item' >
                        <img src={forma4} alt="forma 4" data-tipo="4" width={100} height={100}
                          onDragStart={formaFunc}
                          onTouchStart={(e) => {
                            cerrarModalElementsMenu();
                            formaFunc(e);
                            setOcultarMenu(true);
                          }}
                          onTouchEnd={(e) => {
                            insertarElementoTouch({
                              x: e.changedTouches[0].pageX,
                              y: e.changedTouches[0].pageY,
                            })
                            setOcultarMenu(false);
                          }}
                          style={{ touchAction: 'none' }}
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </div>
              
              {accordionActual == 0 ?
                <div className='dFlex flex-row align-center'>
                  <img className='ple-1' src={texto} alt="Textos" width={100}
                    onDragStart={textFunc}
                    onTouchStart={(e) => {
                      cerrarModalElementsMenu();
                      textFunc(e);
                      setOcultarMenu(true);
                    }}
                    onTouchEnd={(e) => {
                      insertarElementoTouch({
                        x: e.changedTouches[0].pageX,
                        y: e.changedTouches[0].pageY,
                      })
                      setOcultarMenu(false);
                    }}
                    style={{ touchAction: 'none' }}
                  />
                  <Typography className='tiny-font4'>
                    (Arrastra al Floormap)
                  </Typography>
                </div>
                : null
              }
            </div>
          : null
        }
      </>
    )
  }



  return (
    <>
    <Grid container className='justify-center'>
      <Grid item xs={12} sm={12} md={2} >
        {floors.length > 0 ? 
          <Select
            value={floorActual}
            className={sizeScreen == 0.4 ? 'border w100' : 'border mb-2 w100'}
            onChange={
              e => {
                cambiarPiso(e.target.value);
                obtenerEscala(e.target.value);

                floors.forEach((piso) => {
                  if(piso.id == e.target.value){
                    setFloorVisibilidadActual(piso.visibilidad);
                  }
                });
                setMenuStageEnable(false);
              }
            }
            IconComponent={() => (<ExpandMoreIcon htmlColor={"black"}/>)}
          >
            {/* <option key={-1} value={-1} >Todas las zonas</option> */}
            {floors.map((floor, index)  => {
              return(<MenuItem key={floor.id} value={floor.id} >{floor.nombreDelPiso}</MenuItem>)
            })}
          </Select> : null
        }
        
        {(sizeScreen == 1 || sizeScreen == 0.8) && renderElementsMenu()}

        <Modal
          keepMounted
          open={modalElementsMenu}
          onClose={cerrarModalElementsMenu}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className='modal__box2'>
            <div className='dFlex flex-column align-end w100 mb-1'>
              <IconButton
                variant="outlined"
                className='floormap-icon'
                style={{background: "black"}}
                onClick={cerrarModalElementsMenu}
              >
                <Typography style={{color: "white"}}>X</Typography>
              </IconButton>
            </div>
            {renderElementsMenu()}
          </Box>
        </Modal>
        
        
      </Grid>
      <Grid item xs={12} sm={12} md={8} className='dFlex flex-column align-center justify-center'>
        <div className={`${(sizeScreen == 1 ? "flex-row w94" : sizeScreen == 0.8 ? "flex-row w100" : "flex-column w100" )} mb-2 dFlex flex-row align-center justify-between`}>
          <div className={`${(sizeScreen == 1 ? "w50" : sizeScreen == 0.8 ? "w50" : "w100 mb-2" )} `}>
            {sizeScreen != 0.4 && selectedItem && imageNode.attrs.categoria == "forma" && renderShapeMenu()}
            {sizeScreen != 0.4 && selectedItem && imageNode.attrs.categoria == "texto" && renderTextMenu()}
          </div>
          <div className={`${(sizeScreen == 0.4 ? "flex-column" : "w50 flex-row" )} dFlex  align-center justify-between`}>
            <div className={`${(sizeScreen == 0.4 ? "flex-row mb-1" : "flex-row" )} dFlex align-center justify-between`}>
              <IconButton
                variant="outlined"
                className='floormap-icon'
                disabled={arrayDeshacer.length < 1}
                onClick={deshacerFunc}
              >
                <img src={deshacer} alt="deshacer" width="100%" height="100%" />
              </IconButton>
              <IconButton
                variant="outlined"
                className='floormap-icon'
                disabled={arrayRehacer.length < 1}
                onClick={rehacerFunc}
              >
                <img src={rehacer} alt="rehacer" width="100%" height="100%" />
              </IconButton>
            </div>
            <div className={`${(sizeScreen == 0.4 ? "flex-row mb-1" : "flex-row" )} dFlex align-center justify-between`}>
              <IconButton
                disabled={!estadoBotonMasZoom}
                variant="outlined"
                className='floormap-icon'
                onClick={(e) => {
                  zoomFunc(e, 1)
                }}
              >
                <img src={zoomMas} alt="Acercar" width="100%" height="100%" />
              </IconButton>
              <IconButton
                disabled={!estadoBotonMenosZoom}
                variant="outlined"
                className='floormap-icon'
                onClick={(e) => {
                  zoomFunc(e, 2)
                }}
              >
                <img src={zoomMenos} alt="Alejar" width="100%" height="100%" />
              </IconButton>
              <IconButton
                disabled={!estadoBotonMenosZoom}
                variant="outlined"
                className='floormap-icon'
                onClick={(e) => {
                  zoomFunc(e, 0)
                }}
              >
                <img src={reajustar} alt="Reajustar" width="100%" height="100%" />
              </IconButton>
            </div>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              onClick={setFloorItemsFunc}
              className="botonGuardar"
            >
              Guardar
            </Button>
          </div>
        </div>
        
        <Modal
          keepMounted
          open={modalTextoAbierto}
          onClose={cerrarModalTexto}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className='modal__box3'>
            <div>
              <TextField
                id="campoTexto"
                placeholder='Escribe aquí'
                className='border-menu-accordion modal__box__input inside-input2'
                multiline
                maxRows={4}
                variant="filled"
                inputProps={{className: "inside-input"}}
              />
            </div>
            <div className='modal__box__menu justify-evenly'>
              <Button
                variant="contained"
                color="primary"
                className="botonGuardar"
                onClick={cerrarModalTexto}
              >
                Cerrar
              </Button>
              <Button
              variant="contained"
              color="primary"
              className="botonGuardar"
              onClick={crearTexto}
            >
              Aceptar
            </Button>
            </div>
          </Box>
        </Modal>
        <Grid className={sizeScreen == 0.4 ? 'dFlex flex-column align-center justify-center mb-2' : 'dFlex flex-column align-center justify-center mb-4' }
          onDrop={(e) => insertarElemento(e)}
          onDragOver={(e) => e.preventDefault()}
        >
          <Stage
            item md={12}
            width={801 * sizeScreen}
            height={401 * sizeScreen}
            ref={stageRef}
            onClick={() =>{
              setTimeout(() => {
                if(!esDobleClick.current){
                  let openAndCloseMenuStage = selectedItem;
                  setSelectedItem(false);
                  setImageNode(null);
    
                  //Abrir o cerrar menu stage sino se hacia nada
                  if(!openAndCloseMenuStage){
                    setMenuStageEnable(!menuStageEnable)
                  }
                }
              }, 500);
            }}
            onDblTap={() => {
              esDobleClick.current = true;
              abrirModalElementsMenu()
            }}
            scaleX={state.stageScale}
            scaleY={state.stageScale}
            onDragMove={(e) =>{
              limitarDrag(e,1);
            }}
          >
            <Layer>
              {linesA}
              {linesB}
              {images.map((image) => {
                return <URLImage image={image} onBasicClick={onBasicClick} onBasicTap={onBasicTap} onBasicDblClick={onBasicDblClick} limitarDrag={limitarDrag} renovarCordenadas={renovarCordenadas} />;
              })}
            </Layer>
          </Stage>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={2} >
        <div className={sizeScreen == 0.4 ? 'mb-2' : 'mb-4'}>
          {(sizeScreen == 1 || sizeScreen == 0.8) && selectedItem && renderItemMenu()}
          {(sizeScreen == 1 || sizeScreen == 0.8) && menuStageEnable && renderStageMenu()}
          {sizeScreen == 0.4 && selectedItemDbl && selectedItem && 
            <>
              <Modal
                keepMounted
                open={selectedItemDbl}
                onClose={cerrarSelectedItem}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
              >
                <Box className={imageNode.attrs.categoria == "mesa" ? 'modal__box2' : 'modal__box3'}>
                  <div className='dFlex flex-column align-end w100'>
                    <IconButton
                      variant="outlined"
                      className='floormap-icon'
                      style={{background: "black"}}
                      onClick={cerrarSelectedItem}
                    >
                      <Typography style={{color: "white"}}>X</Typography>
                    </IconButton>
                  </div>
                  {renderItemMenu()}
                  <div className='mb-2'>
                    {selectedItem && imageNode.attrs.categoria == "forma" && 
                      <div className='mb-2'>
                        <div className='mb-2 border-bottom'>
                          <Typography className='bold-font text-center'>Ajustes de Forma</Typography>
                        </div>
                        {renderShapeMenu()}
                      </div>
                    }

                    {selectedItem && imageNode.attrs.categoria == "texto" && 
                      <div className='mb-1'>
                        <div className='mb-2 border-bottom'>
                          <Typography className='bold-font text-center'>Ajustes de Texto</Typography>
                        </div>
                        {renderTextMenu()}
                      </div>
                    }
                  </div>
                </Box>
              </Modal>
            </>
          }
          {sizeScreen == 0.4 && menuStageEnable && 
            <>
              <Modal
                keepMounted
                open={menuStageEnable}
                onClose={cerrarMenuStageEnable}
                aria-labelledby="keep-mounted-modal-title"
                aria-describedby="keep-mounted-modal-description"
              >
                <Box className='modal__box3'>
                  <div className='dFlex flex-column align-end w100'>
                    <IconButton
                      variant="outlined"
                      className='floormap-icon'
                      style={{background: "black"}}
                      onClick={cerrarMenuStageEnable}
                    >
                      <Typography style={{color: "white"}}>X</Typography>
                    </IconButton>
                  </div>
                  {renderStageMenu()}
                </Box>
              </Modal>
            </>
          }          
        </div>
        {loading ?
          <SpinnerLoader />
          : null        
        }
      </Grid>
    </Grid>

    </>
  );
};

export default FloormapDistribucion;